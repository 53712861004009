export const portfolios = [
    {
        id: 1,
        title: 'CREATOR',
        url: 'http://wa.me/6289637196946',
        thumbnail: 'https://telegra.ph//file/be3a3d6940cebca124ded.jpg',
        full: 'https://telegra.ph/file/d48620263bc043817be73.png',
        category: 'Web & bot Development',
    },
    {
        id: 2,
        title: 'BOT WHATSAPP',
        url: 'http://wa.me/6288296785106',
        thumbnail: 'https://telegra.ph/file/a4941487bd1ebcf58e427.png',
        full: 'https://telegra.ph//file/be3a3d6940cebca124ded.jpg',
        category: 'Bot WhatsApp',
    },
    {
        id: 3,
        title: 'BOT DISCORD',
        url: 'https://discord.gg/9pZGnj2Ncn',
        thumbnail: 'https://telegra.ph//file/3b80c0cadccffccf2a498.png',
        full: 'https://telegra.ph//file/be3a3d6940cebca124ded.jpg',
        category: 'ChatGPT & Music',
    },
    {
        id: 4,
        title: 'BOT TELEGRAM',
        url: 'http://wa.me/6288296785106',
        thumbnail: 'https://telegra.ph/file/5b4772f82d910136f0cc7.jpg',
        full: 'https://telegra.ph//file/be3a3d6940cebca124ded.jpg',
        category: 'AI & BOT',
    },
]